@use "sass:color";
@use '~@branding/styles.scss' as *;

.dark-mode-theme {
  .create-order-tab-wrapper {
    box-shadow: $brand-box-shadow-dark;
    border: 1px solid $brand-color-dark-block-background;
  }

  .market-depth,
  .cost-info,
  .price-alert-options-types {
    border-color: $brand-color-dark-background-lighter;
  }

  .cost-info {
    margin-top: 16px;
  }

  .market-depth__arrow_up,
  .cost-info__arrow_up {
    border-top: 6px solid $brand-color-dark-contrast;
  }

  .market-depth__arrow_down,
  .cost-info__arrow_down {
    border-bottom: 6px solid $brand-color-dark-contrast;
  }

  .order-options__data-block,
  .cost-info {
    background: $brand-color-dark-block-background;
    color: #FFFFFF;
  }

  .cost-info__category,
  .cost-info__cost-amount_category,
  .cost-info__category__full-width,
  .order-options__data-block-column_left,
  .order-popup__title,
  .market-depth__caption,
  .order-options__forced-open-caption,
  .order-options__data-block-column_right,
  .cost-info__caption {
    color: #FFFFFF;
  }

  .cost-info__expand-all,
  .bp5-icon-import,
  .order-popup__title .bp5-icon-standard  {
    color: $brand-color-dark-selected-background!important;
  }

  .order-options__quantity {
    border-color: $brand-color-dark-input-background;
  }

  .order-options__submit.red-back {
    background-color: $brand-color-dark-sell-button;
  }

  .order-options__submit.blue-back {
    background-color: $brand-color-dark-buy-button;
  }

  .order-options__submit:disabled {
    background-color: #383838;
  }

  .order-options__tab,
  .price-type-options__tab {
    background-color: $brand-color-dark-block-background;
    color: #FFFFFF;
    border: $brand-border-order-options-dark;
  }

  .order-options__tab_selected {
    background-color: $brand-color-dark-selected-background;
    color: #FFFFFF;
  }

  .price-block__price-caption,
  .order-options__caption,
  .order-options__field,
  .order-options__label,
  .cost-info__listItem,
  .cost-info__listItem_bank,
  .cost-info__cost-amount_list,
  .cost-info__cost-amount_list_bank {
    color: $brand-color-dark-contrast;
  }

  .order-options__quantity-limit {
    background-color: transparent;
    color: #FFFFFF;
    line-height: 34px;
  }

  .order-options__tp-input-block,
  .order-options__sl-input-block {
    border-color: $brand-color-dark-input-background;
  }

  .market-depth .b-market-depth-table__tr:nth-child(2n) {
    background-color: #222222;
  }

  .b-market-depth-table__tr:last-child:after {
    background: transparent;
  }

  .price-block__price-value_up, .price-block__direction-arrow_up {
    color: $brand-color-dark-buy;
    border-bottom: 14px solid $brand-color-dark-buy;
  }

  .price-block__price-value_down, .price-block__direction-arrow_down {
    color: $brand-color-dark-sell;
    border-top: 14px solid $brand-color-dark-sell;
  }

  .order-options__price .ant-select {
    .ant-select-selection-item {
      color: #fff !important;
    }
  }

  .order-options__sl .ant-select {
    .ant-select-selection-item {
      color: #8c94a0 !important;
    }

    .ant-select-selector {
      border-color: $brand-color-dark-input-background !important;
    }
  }

  .ant-select-dropdown {
    background-color: $brand-color-dark-background-darker-4;

    .ant-select-item-option {
      color: #fff;

      &.ant-select-item-option-selected {
        background-color: color.adjust($brand-color-dark-background-lighter, $lightness: 10%);
      }

      &:hover,
      &.ant-select-item-option-active {
        background-color: color.adjust($brand-color-dark-background-lighter, $lightness: 3%);
      }
    }
  }
}
@use "sass:color";
@use '~@branding/styles.scss' as *;

.dark-mode-theme {
  .watchlist-separator {
    border-bottom: 1px solid $brand-color-dark-background-darker-3;
  }

  .watchlist-pair-view {
    color: #FFFFFF;
  }

  .b-watchlist__row:hover > div,
  .watchlist-pair-view:hover .item-inner {
    background: color.adjust($brand-color-dark-background-darker-3, $lightness: 5%);
  }

  .b-watchlist__row.forced,
  .watchlist-pair-view.forced .item-inner {
    background: $brand-color-dark-background-darker-3;
  }

  .watchlist-pair-view.forced:hover .item-inner,
  .b-watchlist__row.forced:hover {
    background: color.adjust($brand-color-dark-background-darker-3, $lightness: 10%);
  }

  .watchlist-quantity-popup,
  .watchlist-tp-ts-popup {
    color: #FFFFFF;
  }

  .watchlist-quantity-popup .popup-head .values div strong {
    color: #FFFFFF;
  }

  .watchlist-pair-view .item-foot .cols>div strong {
    color: #909090;
  }

  .watchlist-quantity-popup,
  .watchlist-tp-ts-popup {
    .bp5-numeric-input .bp5-button-group.bp5-vertical>.bp5-button,
    .bp5-select select {
      background: $brand-color-dark-background-darker-3 !important;
      color: #909090;
    }

    .popup-head {
      border-color: $brand-color-dark-background-darker-3;
    }

    .popup-head .enter-value input,
    .input-value input {
      border: 1px solid rgba(16,22,26,.4);
    }

    .bp5-button-group:not([class*=bp5-intent-]),
    .bp5-button:not([class*=bp5-intent-]) {
      -webkit-box-shadow:1px 1px 6px rgba(16,22,26,.4);
      box-shadow:1px 1px 6px rgba(16,22,26,.4)
    }

    .default-order-numeric-input {
      .bp5-button {
        box-shadow: 0 0 0 1px #333333!important;
      }
    }

    .bp5-input::placeholder {
      color: #909090;
    }
  }
}
.layout {
  // We should exclude header (single line)
  height: calc(100% - 113px);
  overflow-y: auto;
}

@media (max-width: 900px) {
  .layout {
    // We should exclude header (single line)
    height: calc(100% - 133px);
    overflow-y: auto;
  }
}


@use '~@branding/styles.scss' as *;
@use "../../../styles/partials/variables";

.search-multi-select {
  width: 100%;

  .bp5-popover-target {
    width: calc(100% - 10px);

    input {
      font-size: 14px;
    }
  }
}

.search-multi-select_disabled.bp5-input.bp5-fill {
  width: calc(100% - 20px);
  padding-left: 19px;
}
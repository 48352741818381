.capital-figures {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  flex-wrap: wrap;
  overflow: hidden;
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  width: 100%;
  min-width: 250px;

  > *:not(:first-child) {
    margin-left: 12px;
  }

  > div {
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 50px;
    height: 50px;
    .bp5-popover-target {
      min-width: 50px;
    }
  }

  &__balance {
    cursor: pointer;
  }

  &__figure {
    text-align: left;
    white-space: nowrap;
  }

  &__value {
    font-family: 'Arial', monospace;
    text-transform: uppercase;
    font-weight: bold;
  }
}

@media (max-width: 1400px) {
  .capital-figures {
    font-size: 13px;
    max-width: 650px;
  }
}
@use '~@branding/styles.scss' as *;

.dark-mode-theme {
  .sidenav {
    background-color: $brand-color-dark-base;
  }

  .b-popover.bp5-popover {
    border-color: #333333;
  }

  .menu-item {
    &.active {
      background-color: $brand-color-dark-selected-background;
      border-bottom-color: $brand-color-dark-selected-background;
    }
  }

  .control-switch {
    input {
      &:checked + .switch_elem {
        background-color: $brand-color-switch-background;
      }
    }
  }
}
@use "sass:color";
@use "profile-dark-mode";

.profile-popover {
  min-width: 300px;

  .bp5-menu {
    padding: 20px
  }

  button.bp5-button {
    text-align: center;
    width: 100%;
    line-height: 40px;
    font-size: 16px;

    &.btn-logout {
      &:hover {
        // please remove important from:
        background-color: color.adjust(#FFFFFF, $lightness: -5%)!important;
      }

      .bp5-icon {
        color: #000000;
      }
    }

  }
}

.avatar-wrapper {
  width: 48px;
  height: 48px;
  position: relative;

  .demo-sign {
    position: absolute;
    height: 18px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ed5328;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
  }
}

.profile-popover-target {
  .user-wrapper {
    margin-right: 10px;
    max-width: 300px;

    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.compact * {
      line-height: 16px;
    }

    .user {
      text-align: right;

      .user-name-wrapper {
        :first-child {
          font-size: 16px;
        }
      }
    }
  }
}

// In the header we have problems with long account name only in this resolutions
@media (max-width: 1530px) {
  .profile-popover-target {
    .user-wrapper {
      max-width: 150px;
    }
  }
}
@use "sass:color";
@use '~@branding/styles.scss' as *;

.dark-mode-theme {
  .react-grid-Container {
    .react-grid-Toolbar,
    .react-grid-Main,
    .react-grid-Grid,
    .react-grid-Canvas,
    .react-grid-Header,
    .react-grid-HeaderCell,
    .b-trade-grid__header-value {
      background: $brand-color-dark-background-darker-2;
      color: #FFFFFF;
      border: 0;
      outline: none;
    }

    .b-trade-grid__header-value {
      background: $brand-color-dark-base;
      text-transform: uppercase;
    }
    .filter-rows-active {
      background-color: $brand-color-dark-selected-background;
    }

    .react-grid-HeaderRow input[type="text"] {
      box-shadow: none;
      border: 0;
      border-radius: 4px;
      border: 1px solid #333333;
      color: white;
      background-color: transparent;
      background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Ccircle id='path-1' cx='6.5' cy='6.5' r='6.5'%3E%3C/circle%3E%3C/defs%3E%3Cg id='assets' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-600.000000, -404.000000)'%3E%3Crect id='Rectangle-31' x='600' y='404' width='16' height='16'%3E%3C/rect%3E%3Cg id='Group-115' transform='translate(601.000000, 405.000000)'%3E%3Cg id='Oval-5'%3E%3Cuse fill-opacity='0' fill='%23FFFFFF' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3Ccircle stroke='%235a5a5a' stroke-width='1' cx='6.5' cy='6.5' r='6'%3E%3C/circle%3E%3C/g%3E%3Cpath d='M11,11 L14,14' id='Path-10' stroke='%235a5a5a' stroke-linecap='round'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

      &::placeholder {
        color: #909090;
      }
    }

    .react-grid-Row {
      box-shadow: 0 2px 1px -1px #333333;
    }

    .react-grid-HeaderCell__draggable {
      border-color: #333333
    }

    .close {
      color: #909090;
      text-shadow: none;
    }

    .b-trade-grid__close {
      background-color: #333333;
      color: #FFFFFF;
      border: 1px solid $brand-color-dark-base;
    }

    .b-trade-grid__adjust,
    .b-trade-grid__adjust:focus,
    .b-trade-grid__adjust:hover,
    .b-trade-grid__close:focus:enabled,
    .b-trade-grid__close:hover:enabled {
      background: color.adjust(#333333, $lightness: 5%);
      border-color: $brand-color-dark-button-border;
    }
  }


  .watchlist-pair-views {
    .watchlist-pair-view.close {
      text-shadow: none;
    }
  }

  // Corporate actions?
  .b-trade-grid .react-grid-Cell {
    color: #FFFFFF;
    border-bottom: 1px solid $brand-color-dark-base;
    background: transparent;
  }

  .b-trade-grid .react-grid-Row:hover .react-grid-Cell {
    color: #FFFFFF;
    background: transparent;
  }

  .b-instrument-status-circle--tradeable {
    background: $brand-color-dark-buy;
  }


  .b-instrument-status-circle--not-tradeable {
    background: $brand-color-dark-sell;
  }

  .b-instrument-status-circle--market-frozen {
    border-color: $brand-color-dark-buy;
    background: #fff;
  }

  .b-instrument-status-circle--market-discontinued {
    border: 1px solid #FFFFFF;
  }
}
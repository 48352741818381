// This is a separate file as it will be included only once globally to define font-families.

@font-face {
  font-family: 'Benton BBVA';
  src: url('BBVA/BentonSansBBVA-Light.eot'); /* IE9 Compat Modes */
  src: url('BBVA/BentonSansBBVA-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('BBVA/BentonSansBBVA-Light.woff2') format('woff2'), /* Modern Browsers */
  url('BBVA/BentonSansBBVA-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('BBVA/BentonSansBBVA-Light.svg') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  100;
}

@font-face {
  font-family: 'Benton BBVA';
  src: url('BBVA/BentonSansBBVA-Book.eot'); /* IE9 Compat Modes */
  src: url('BBVA/BentonSansBBVA-Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('BBVA/BentonSansBBVA-Book.woff2') format('woff2'), /* Modern Browsers */
  url('BBVA/BentonSansBBVA-Book.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('BBVA/BentonSansBBVA-Book.svg') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Benton BBVA';
  src: url('BBVA/BentonSansBBVA-Medium.eot'); /* IE9 Compat Modes */
  src: url('BBVA/BentonSansBBVA-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('BBVA/BentonSansBBVA-Medium.woff2') format('woff2'), /* Modern Browsers */
  url('BBVA/BentonSansBBVA-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('BBVA/BentonSansBBVA-Medium.svg') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  600;
}

@font-face {
  font-family: 'Benton BBVA';
  src: url('BBVA/BentonSansBBVA-Bold.eot'); /* IE9 Compat Modes */
  src: url('BBVA/BentonSansBBVA-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('BBVA/BentonSansBBVA-Bold.woff2') format('woff2'), /* Modern Browsers */
  url('BBVA/BentonSansBBVA-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('BBVA/BentonSansBBVA-Bold.svg') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}
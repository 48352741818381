@use '~@branding/styles.scss' as *;
@use "../../../styles/partials/variables" as *;


.triple-state-select {
  .bp5-button-group {
    box-shadow: 0 0 0 1px $color-border;

    .bp5-button {
      margin: 0;
      border: 0;

      &.bp5-active {
        background-color: #d8e1e8!important;

        &:hover {
          background-color: #d8e1e8!important;
        }
      }
    }
  }
}

.dark-mode-theme {
  .bp5-button-group {
    .bp5-button {
      &.bp5-active {
        background-color: $brand-color-dark-selected-background!important;

        &:hover {
          background-color: $brand-color-dark-selected-background!important;
        }
      }
    }
  }
}


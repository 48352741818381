@use "sass:color";
@use '~@branding/styles.scss' as *;

.dark-mode-theme {
  .b-messages__header {
    background-color: #111111;
    border-color: #222222;
  }

  .b-messages {
    background-color: $brand-color-dark-background-darker-4;

    .b-messages__body {
      .b-messages__messages-sidebar {
        border-color: #222222;
      }
    }

    .b-message-controls__gray-btn {
      line-height: 20px;
    }
  }

  .create-message-dialog {
    .bp5-input {
      box-shadow: 0 0 0 1px #6b6b6b
    }
  }

  .b-messages-list__description {
    color: #FFFFFF;
  }

  .b-messages-list__message {
    border-color: #222222;
  }

  .b-messages-list__message--active {
    background-color: color.adjust(#909090, $lightness: -5%);
    color: #FFFFFF;

    .b-messages-list__description {
      color: #FFFFFF;
    }
  }
}
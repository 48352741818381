@use "sass:color"; 
@use '~@branding/styles.scss' as *;

body.dark-mode-theme {
  // This should be implemented globally

  color: #FFFFFF;
  background-color: $brand-color-dark-background-darker-1;

  // site
  body, h1, h2, h3, h4, h5, h6, td {
    color: #FFFFFF;
  }

  a.highlight {
    color: #00e1ff;
  }

  hr {
    border-top: 1px solid rgba(255,255,255,.1);
  }

  // site
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144,144,144,0.3);
  }

  .orange {
    color: $brand-color-dark-orange;
  }

  .green {
    color: $brand-color-dark-green;
  }

  .blue {
    color: $brand-color-dark-blue;
  }

  .red {
    color: $brand-color-dark-red;
  }

  .price-green {
    color: $brand-color-dark-buy !important;
  }

  .price-red {
    color: $brand-color-dark-sell !important;
  }

  // blueprints
  /**
  Tooltips
   */
  .bp5-tooltip2 .bp5-popover-content{
    background: $brand-color-dark-tooltip-background;
    color:#FFFFFF
  }
  .bp5-tooltip2 .bp5-popover-arrow:before {
    -webkit-box-shadow:1px 1px 6px rgba(16,22,26,.2);
    box-shadow:1px 1px 6px rgba(16,22,26,.2)
  }
  .bp5-tooltip2 .bp5-popover-arrow-border,
  .bp5-popover .bp5-popover-arrow-border {
    fill:#10161a;
    fill-opacity:.1
  }
  .bp5-tooltip2 .bp5-popover-arrow-fill,
  .bp5-popover .bp5-popover-arrow-fill {
    fill:$brand-color-dark-tooltip-background
  }

  // blueprints
  /**
  Datepicker
   */
  .bp5-daterangepicker,
  .date-picker-container {
    background-color: $brand-color-dark-background-lighter;
    color: #FFFFFF;
  }

  // toast
  .bp5-toast {
    .bp5-button-group.bp5-minimal {
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }
  }

  // blueprints
  .bp5-datepicker {
    background-color: $brand-color-dark-background-lighter;
    color: #FFFFFF;

    .bp5-datepicker-caption select {
      background-color: $brand-color-dark-background-lighter;
      color: #FFFFFF;

      option:hover {
        box-shadow: 0 0 10px 100px #222222 inset;
      }
    }

    .DayPicker-Day.DayPicker-Day--outside {
      color: #FFFFFF;
    }

    .DayPicker-Day.DayPicker-Day--disabled,
    .DayPicker-Day--isInvalidDate {
      color: #909090;
    }

    .DayPicker-NavButton {
      color: #909090;
    }

    .bp5-datepicker-caption-caret {
      fill: #909090;
    }

    .DayPicker-Day:focus,
    .DayPicker-Day:hover {
      &:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
        background-color: color.adjust($brand-color-dark-background-lighter, $lightness: 5%) !important;
        color: #FFFFFF;
      }
    }

    .DayPicker-Day--selected-range,
    // WTF - why they use this ? ;)
    .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
      background-color: color.adjust($brand-color-dark-background-lighter, $lightness: 10%) !important;
    }
  }

  .b-gray-btn {
    background-color: $brand-color-dark-background-lighter;
    color: #ffffff;
    border: #444444;

    &:hover {
      background-color: color.adjust($brand-color-dark-background-lighter, $lightness: 5%);
    }
  }


  // order-window
  //TODO: Why do we need important?
  .bp5-datepicker .DayPicker-Day--isInvalidDate {
    background-color: transparent!important;
    color: #909090!important;
  }

  .bp5-menu {
    background-color: $brand-color-dark-background-darker-4;
    color: #FFFFFF;
    border-radius: 0;

    .bp5-menu-item:hover:not(.bp3-active),
    .bp5-menu-item:focus:not(.bp3-active),
    .bp5-submenu>.bp5-popover-open>.bp5-menu-item {
      background-color: color.adjust($brand-color-dark-background-lighter, $lightness: 3%) !important;
    }

    .bp5-menu-divider {
      border-top: 1px solid #222222;
    }

    .bp5-menu-item .bp5-icon,
    .bp5-menu-item:before {
      color: #909090;
    }

    .bp5-menu-item-label {
      color: #FFFFFF;
    }
  }



  // blueprints & site
  /**
  dialog
   */
  .bp5-dialog {
    background-color: $brand-color-dark-background-darker-4;
    box-shadow: $brand-box-shadow-dark-dialog;

    .ant-input-number {
      border: none;
    }

    .bp5-input,
    .ant-input-number {
      box-shadow: 0 0 0 1px #222;
    }
  }

  .bp5-dialog-header,
  .bp5-dialog-footer {
    background-color: $brand-color-dark-background-darker-4;

    .bp5-icon, .bp5-icon-large {
      color: #909090;
    }
  }

  // I dont know why, but for instrument search we are using it outside of bp5-dialog
  .bp5-dialog-body {
    background-color: $brand-color-dark-background-darker-4;


    &.search-dialog-body {
      background-color: $brand-color-dark-background-darker-2;
    }
  }

  .modal-dialog {
    .modal-content {
      background-color: $brand-color-dark-background-darker-4;
      border: 1px solid #222222;

      .modal-footer {
        border-top: 1px solid #222222;
      }
    }
  }

  // site & blueprints
  /**
  Popover
   */
  .bp5-popover {
    box-shadow: 0 0 0 1px $brand-color-dark-tooltip-background !important;
    border-radius: 0;
    background-color: $brand-color-dark-background-lighter;

    .bp5-popover-content {
      border-radius: 0;
      background-color: $brand-color-dark-background-lighter;
    }

    .bp5-popover-arrow-fill {
      fill: $brand-color-dark-background-lighter;
    }

    .bp5-popover-arrow-border {
      fill: $brand-color-dark-tooltip-background;
      fill-opacity: 1;
    }
  }

  /**
  Card
   */

  .bp5-card {
    box-shadow: 0 0 0 1px $brand-color-dark-tooltip-background !important;
    border-radius: 0;
    background-color: $brand-color-dark-background-lighter;
  }

  /**
  Pagination
   */

  .rc-pagination-item {
    background-color: #222222;
    color: #FFFFFF;

    a {
      color: #FFFFFF;
    }
  }

  .rc-pagination-next,
  .rc-pagination-prev,
  .rc-pagination-item {
    border-color: $brand-color-dark-background-lighter;

    &.rc-pagination-item-active {
      border-color: #1890ff;
      a {
        color: #1890ff;
      }
    }

    button {
      border: 0;
      background-color: #222222;
      color: #FFFFFF;
    }
  }

  .rc-pagination-simple-pager {
    input {
      background: #909090;
      color: #FFFFFF;
      border: $brand-color-dark-background-lighter;
    }
  }

  .rc-pagination-next a,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link,
  .rc-pagination-prev a,
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link {
    color: #FFFFFF;

    a {
      color: #FFFFFF;
    }
  }

  .rc-pagination-disabled > a,
  .rc-pagination-simple .rc-pagination-disabled > .rc-pagination-item-link {
    color: $brand-color-dark-background-lighter;
  }

  .rc-pagination-disabled .rc-pagination-item-link, .rc-pagination-disabled:focus .rc-pagination-item-link, .rc-pagination-disabled:hover .rc-pagination-item-link {
    color: #909090
  }

  .ant-pagination {
    .ant-pagination-item:not(.ant-pagination-item-active),
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-jump-prev .ant-pagination-item-link .ant-pagination-item-container,
    .ant-pagination-jump-next .ant-pagination-item-link .ant-pagination-item-container,
    .ant-pagination-next .ant-pagination-item-link {
      background-color: #222222;

      a, svg, .ant-pagination-item-ellipsis {
        color: #FFFFFF;
      }
    }

    .ant-pagination-item-active {
      background-color: #222222;
    }
  }

  .ant-pagination-options {
    .ant-select {
      .ant-select-selection-item,
      .ant-select-selector,
      .ant-select-item-option-content,
      .ant-select-item-option-active,
      .ant-select-item-option-selected,
      .ant-select-dropdown {
        background-color: #222222;
        color: #FFFFFF;
      }
    }
  }

  // tree
  // blueprint
  .bp5-tree-node-caret {
    color: #909090;
  }
  //site
  .tabs-container > .bp5-tab-list .bp5-tab-indicator-wrapper .bp5-tab-indicator,
  .bp5-tree-node.bp5-tree-node-selected>.bp5-tree-node-content {
    background-color: color.adjust($brand-color-dark-selected-background, $lightness: -5%);
  }

  // site
  .menu-right-border {
    border-right: 0;
  }

  // site.scss
  .b-btn-group__btn,
  .react-grid-Toolbar .tools .btn,
  a.b-btn-group__btn,
  input.b-btn-group__btn,
  .b-sell-buy-btn--buy,
  .b-sell-buy-btn--sell {
    background-color: $brand-color-dark-tooltip-background;
    border-color: $brand-color-dark-button-border;
    color: #FFFFFF;

    &.b-btn-group__btn--active {
      background-color: $brand-color-dark-selected-background;
    }
  }

  .tab-search .react-grid-Toolbar .tools .btn {
    border-color: $brand-color-dark-button-border !important;
  }

  // site.scss
  .b-btn-group__btn:last-child {
    border-right-color: $brand-color-dark-button-border !important;
  }

  // site.scss
  .bp5-input,
  .bp5-file-upload-input,
  .ant-input-number {
    box-shadow: 0 0 0 1px $brand-color-dark-background-lighter;
    color: #FFFFFF;

    &:not(:disabled) {
      background-color: transparent;
    }

    &::placeholder,
    input::placeholder {
      color: #909090;
    }
  }

  .ant-input-number .ant-input-number-handler-wrap {
    background-color: inherit;

    > span {
      border-color: inherit;

      > span {
        color: #909090;
      }
    }
  }

  // site.scss
  .filter-toolbar .bp5-input {
    box-shadow: none;
    border-radius: $brand-border-radius-dark-filter-border-radius;
    border: 1px solid $brand-color-dark-background-lighter;
    color: white;
    font-size: 14px;

    &::placeholder {
      color: #909090;
    }
  }

  // site
  .bp5-button[class*=bp5-icon-]:before,
  .bp5-button .bp5-icon,
  .bp5-button .bp5-icon-large,
  .bp5-button .bp5-icon-standard,
  .bp5-numeric-input .bp5-button-group.bp5-vertical>.bp5-button,
    // blueprint
  .bp5-input-group>.bp5-icon {
    color: #909090;

    &:hover {
      color: color.adjust(#909090, $lightness: -5%);
    }
  }

  .bp5-input-group.bp5-intent-danger > .bp5-input {
    box-shadow: 0 0 0 0 rgba(219,55,55,0), 0 0 0 0 rgba(219,55,55,0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16,22,26,.15), inset 0 1px 1px rgba(16,22,26,.2)
  }

  .tabs-container > .bp5-tab-panel .filter-toolbar .col-separator {
    border-right: 1px solid #2d2d2d;
  }

  // instruments.scss
  .instrument__price--none,
  .instrument__price {
    color: #FFFFFF;
  }

  // instruments
  .instrument__trade-button {
    color: #909090;

    .icon {
      color: #FFFFFF;
    }
  }

  //order-window
  .order-options__validity-input {
    background: transparent;
    color: #FFFFFF;
  }

  .default-order-numeric-input .bp5-numeric-input .bp5-button-group.bp5-vertical>.bp5-button,
  .bp5-select select {
    background: $brand-color-dark-background-lighter !important;
    color: #FFFFFF;
  }

  .bp5-button-group:not([class*=bp5-intent-]),
  .bp5-button:not([class*=bp5-intent-]) {
    -webkit-box-shadow:1px 1px 6px rgba(16,22,26,.2);
    box-shadow:1px 1px 6px rgba(16,22,26,.2);
  }

  .default-order-numeric-input {
    .bp5-button {
      box-shadow: 0 0 0 1px $brand-color-dark-background-lighter !important;
    }
  }

  //blueprint
  .bp5-control.bp5-disabled {
    color: #ffffff;
  }

  .bp5-button-group, .bp5-button {
    &:not(.bp5-minimal) {
      > .bp5-popover-target .bp5-button, > .bp5-button {
        border-left: 1px solid #222222;
      }
    }

    &.bp5-button.b-layout-popover__save {
      box-shadow: 0 0 0 1px #222222 !important;

      &:not(.bp5-disabled):hover {
        background-color: $brand-color-dark-background-lighter !important;
      }
    }

    &:not([class*="bp5-intent-"]) {
      background: transparent;
      box-shadow: 0 0 0 1px #222222;
      border-width: 0;

      &:hover {
        background: color.adjust(#909090, $lightness: -5%);
        box-shadow: 0 0 0 1px #222222;
        border-width: 0;
      }

      &.bp5-disabled {
        box-shadow: 0 0 0 1px #222222 !important;
        background-color: #909090 !important;
        color: #FFFFFF !important;
      }
    }
  }

  .bp5-button.b-btn-blue:not([class*=bp5-intent-]),
  .b-layout-popover__save-as {
    border-color: #222222;
  }

  .bp5-control-indicator, .bp5-checkbox:hover .bp5-control-indicator {
    background: #909090;
  }

  //site
  /* search field*/
  .b-search {
    border: 1px solid #222222;
  }

  .b-search__icon {
    color: #909090;
  }

  .b-search__input {
    border: none;
    color: #ffffff;
  }

  .b-search__input::-webkit-input-placeholder,
  .b-search__input::-moz-placeholder,
  .b-search__input:-ms-input-placeholder,
  .b-search__input:-moz-placeholder {
    color: #909090;
  }

  // body has this flag too
  &.general-spinner,
  .general-spinner {
    background-image: url("../imgs/dark/loading-spinner.svg");
  }

  .bp5-file-upload-input {
    box-shadow: 0 0 0 1px #222;

    &:after {
      color: #FFFFFF;
      background: $brand-color-dark-background-lighter;

      &:hover {
        background: color.adjust($brand-color-dark-background-lighter, $lightness: -5%);
      }
    }
  }

  // numeric inputs inside modal-forms
  .modal-form {
    .bp5-numeric-input .bp5-button-group.bp5-vertical > .bp5-button,
    .bp5-select select {
      background: #222222 !important;
      color: #909090;
    }
  }

  .b-small-close, .b-watchlist__close-btn {
    color: #ffffff;
    opacity: 0.60;
    &:hover {
      opacity: 1;
    }
  }

  .b-news__news-sidebar .filter-modified {
    color: #E8974C;
  }

  .b-trade-grid__close {
    .bp5-spinner-head {
      stroke: rgba(255,255,255,0.9);
    }
  }

  .filter-col .save-button.bp5-icon-import,
  .news-container .save-button.bp5-icon-import {
    color: #ffffff!important;
  }

  .bp5-logo {
    @include brand-mixin-dark-logo;
  }

  .ant-table-filter-dropdown {
    color: #fff;
    background: $brand-ant-table-bg-dark;
    box-shadow: 0 3px 6px -4px rgba(255,255,255,.12), 0 6px 16px 0 rgba(255,255,255,.08), 0 9px 28px 8px rgba(255,255,255,.05);
  }
  .ant-table {
    background: transparent;
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: $brand-color-dark-background-darker-2;
    }
    .ant-table-thead > tr > th {
      background: $brand-color-dark-background-darker-2;
      color: #ffffff;
      font-weight: bold;
    }
    .ant-empty-normal {
      color: #ffffff;
    }
    .ant-table-cell-fix-left, .ant-table-cell-fix-right {
      background: $brand-color-dark-background-darker-2;
    }
    .ant-table-filter-trigger-container {
      background: transparent;
    }
    .ant-table-filter-trigger-container:hover, .ant-table-filter-trigger-container-open {
      background: #333333;
    }
    td {
      background: transparent;

    }
    .ant-table-expanded-row-fixed,
    &.ant-table-bordered > .ant-table-container, th, td,
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
      background: transparent;
      border-color: #333333 !important;

      &:after {
        border-color: #333333 !important;
      }

      .ant-table-cell-scrollbar {
        box-shadow: 0 1px 0 1px #333;
      }
    }
  }

  .bp5-tab, .bp5-tab:hover {
    color: #ffffff;
  }

  .bp5-intent-none {
    color: #333333;
  }

  .menu-item-img {
    padding: 4px;
  }

  .order-type-select .change-order-type-button  {
    fill: #909090
  }
}
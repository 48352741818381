@use "product-list-dark-mode";

.product-edit-list-container {
  .react-grid-HeaderRow .react-grid-checkbox-container {
    height: 26px;
    top: 26px;
    position: relative;
    width: 100%;
    background: #f5f5f5;

    .react-grid-checkbox:focus+.react-grid-checkbox-label {
      outline: 0 !important;
    }
  }

  .react-grid-Toolbar {
    .tools {
      min-width: 1150px;

      .products-additional-controls {
        flex: 1;

        > button {
          border-radius: 4px;
        }

        .products-amount {
          border-radius: 4px 0 0 4px;
        }
      }

      .bp5-popover-target .bp5-button-text {
        line-height: 28px;
      }

      .bp5-input-group {
        .bp5-input {
          height: 28px;
        }

        .bp5-icon {
          top: 0;
        }
      }
    }
  }

  .react-grid-HeaderRow:nth-child(2) .react-grid-HeaderCell.react-grid-HeaderCell--locked:last-child {
    display: none !important;
  }

  .react-grid-HeaderRow:first-child .react-grid-HeaderCell.react-grid-HeaderCell--locked:last-child {
    z-index: 2;
  }

  .react-grid-Viewport {
    z-index: 2;
  }

  .react-grid-Header {
    z-index: 2;
  }

  #edit-product-button:disabled {
    opacity: 0.5;
  }

  #edit-multiple-products-button:disabled,
  #delete-multiple-products-button:disabled,
  #create-products-button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
@use "sass:color"; 

$brand-color-grid-cell-border: #e4ecf1;
$brand-color-primary: #2e4fa0;
$brand-color-sidebar-background: #384047;

$brand-color-dark-base: #072146;
$brand-color-dark-background-darker-1: color.adjust($brand-color-dark-base, $lightness: -10%);
$brand-color-dark-background-darker-2: color.adjust($brand-color-dark-base, $lightness: -10%);
$brand-color-dark-background-darker-3: color.adjust($brand-color-dark-base, $lightness: -10%);
$brand-color-dark-background-darker-4: color.adjust($brand-color-dark-base, $lightness: -10%);
$brand-color-dark-background-lighter: color.adjust($brand-color-dark-base, $lightness: -5%);
$brand-color-dark-contrast: white;
$brand-color-dark-selected-background: #49a5e6;
$brand-color-switch-background: #49a5e6;
$brand-color-dark-block-background: $brand-color-dark-base;
$brand-color-dark-input-background: $brand-color-dark-base;
$brand-color-dark-instrument-title: #06c2d4;
$brand-color-dark-orange: #ebbb40;
$brand-color-dark-green: #85ff00;
$brand-color-dark-blue: #06c2d4;
$brand-color-dark-red: #bf0411;
$brand-color-dark-tooltip-background: $brand-color-dark-base;
$brand-color-dark-button-border: color.adjust($brand-color-dark-base, $lightness: -3%);
$brand-border-radius-dark-filter-border-radius: 0;

$brand-color-buy: #44a718;
$brand-color-dark-buy: #85ff00;
$brand-color-dark-buy-button: color.adjust($brand-color-dark-buy, $lightness: -10%);
$brand-color-sell: #bf0411;
$brand-color-dark-sell: #ff006a;
$brand-color-dark-sell-button: color.adjust($brand-color-dark-sell, $lightness: -10%);
$brand-color-price-up: $brand-color-buy;
$brand-color-dark-price-up: $brand-color-dark-buy;
$brand-color-price-down: $brand-color-sell;
$brand-color-dark-price-down: $brand-color-dark-sell;
$brand-color-change-rate-up: $brand-color-buy;
$brand-color-dark-change-rate-up: $brand-color-dark-buy;
$brand-color-change-rate-down: $brand-color-sell;
$brand-color-dark-change-rate-down: $brand-color-dark-sell;
$brand-color-highlight-error-text: $brand-color-price-down;
$brand-color-dark-highlight-error-text: $brand-color-dark-price-down;

$brand-box-shadow-dark: 0 0 8px 0 $brand-color-dark-base, 0 0px 20px 0 $brand-color-dark-base;
$brand-box-shadow-dark-dialog: 0 8px 40px 0 $brand-color-dark-background-darker-2;
$brand-font-weight-order-popup-title: normal;
$brand-font-weight-grid-close-button: normal;
$brand-width-order-popup: 500px;
$brand-border-order-options-dark: none;

$brand-ant-table-bg-dark: #020b18;

@mixin brand-mixin-close-all-button {
  margin-left: 30px;
  font-size: 16px;
}

@mixin brand-mixin-logo {
  background: url('~@branding/img/bbva-light.svg') no-repeat local center;
  width: 250px;
  height: 50px;
}

@mixin brand-mixin-dark-logo {
  background: url('~@branding/img/bbva-dark.svg') no-repeat local center;
  width: 250px;
  height: 50px;
}

$brand-font-family: 'Benton BBVA';

$layouts-save-btn: #2196f3;



.dark-mode-theme {
  .bp5-input-group.input-custom-background {
    >.bp5-input {
      border-radius: 0;
    }
    >.bp5-icon {
      text-shadow: none;
      opacity: 1;
    }
  }

  .instrument__price--none,
  .instrument__price {
    color:#FFFFFF;
  }

  // instruments
  .instrument__trade-button {
    color:#909090;
  }
}
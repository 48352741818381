@use "sass:color";

.dark-mode-theme {
  .b-news-list__new {
    border-bottom: 1px solid #222222;

    .b-news-list__description,
    .b-news-list__title {
      color: #ffffff;
    }

    .b-news-content__date,
    .b-news-list__date {
      color: #909090;
    }
  }

  .b-news-list--active {
    background-color: color.adjust(#909090, $lightness: -5%);
    color: #FFFFFF;

    .b-news-list__title,
    .b-news-list__description {
      color: #FFFFFF;
    }
  }
}
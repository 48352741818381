@use "sass:color"; 

.dark-mode-theme {
  .profile-popover {
    border-color: #464646;

    button.bp5-button {

      &.btn-logout {
        color: #06c2d4;
        background-color: #464646;

        &:hover {
          // please remove important from:
          background-color: color.adjust(#464646, $lightness: 5%)!important;
        }

        .bp5-icon {
          color: #06c2d4;
        }
      }

    }
  }
}
.dark-mode-theme {
  .container-settings .menu {
    background: #333333;
    color: #FFFFFF;
    border: 1px solid #222222;
  }

  .container-settings {
    .item-highlighted {
      background: #222222;
    }

    .bp5-disabled:not(.bp5-button) {
      opacity: 0.7;
    }

    .distance-settings-spinner {
      background-image: url("../../../imgs/dark/loading-spinner.svg");
    }
  }

  .settings-confrimation-dialog .bp5-dialog-body {
    background: transparent;
  }
}
@use '~@branding/styles.scss' as *;

$toolbar-color: $brand-color-dark-background-darker-3;

.dark-mode-theme {
  .mosaic.mosaic-blueprint-theme {

    .mosaic-zero-state {
      background-color: $toolbar-color;
    }

    .mosaic-root {
      .mosaic-window {
        box-shadow: 0 0 0 1px $brand-color-dark-background-darker-4;

        .mosaic-window-toolbar {
          background-color: $toolbar-color;

          &:hover {
            background-color: $toolbar-color;
          }

          .mosaic-window-title:before {
            content: url("../../../imgs/dark/drag.svg");
            opacity: 1;
          }
        }
      }

      .tabs-container > .bp5-tab-panel {
        background-color: $brand-color-dark-background-darker-2;
        border-top-color: $brand-color-dark-background-darker-4;

        &.tab-home {
          background-color: $brand-color-dark-background-darker-4;
        }
      }

      .tabs-container > .bp5-tab-list {

        .bp5-tab {
          border-color: $brand-color-dark-background-darker-4;

          .tab-title {
            color: #FFFFFF;
          }

          &[aria-selected="true"] {
            border-bottom-color: $brand-color-dark-background-darker-2;
            background-color: $brand-color-dark-background-darker-2;
          }
        }

        & > a {
          color: #909090;
          background: transparent;
          border-right: 0;

          &.tab-list-nav-left,
          &.tab-list-nav-right,
          &.add-tab-button-right {
            background: $toolbar-color;
          }
        }
      }
    }
  }

  .bp5-button:not([class*=bp5-intent-]) {
    color: #FFFFFF;
  }

  .mosaic-window-toolbar .bp5-button-group:not(.bp5-minimal)>.bp5-button {
    border: 0;
  }

  .home-popover-popover__content .home-popover-tab-instance span:hover,
  .home-popover-popover__content .ca-popover-menuitem:hover {
    background-color: transparent;
    color: #909090;

    h5 {
      color: #909090;
      font-weight: 500;
    }
  }
}
@use "underlying-list-dark-mode";

.underlying-edit-list-container .react-grid-HeaderRow .react-grid-checkbox-container {
  height: 26px;
  top: 26px;
  position: relative;
  width: 100%;
  background: #f5f5f5;
}

.underlying-edit-list-container .react-grid-HeaderRow .react-grid-checkbox-container .react-grid-checkbox:focus+.react-grid-checkbox-label {
  outline: 0 !important;
}

.underlying-edit-list-container .react-grid-Toolbar {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 5px 5px 5px;

  .tools {
    min-width: 1080px;

    .underlyings-additional-controls {
      flex: 1;

      > button {
        border-radius: 4px;
      }

      .underlyings-amount {
        border-radius: 4px 0 0 4px;
      }
    }
  }
}

.underlying-edit-list-container .react-grid-HeaderRow:nth-child(2) .react-grid-HeaderCell.react-grid-HeaderCell--locked:last-child {
  display: none !important;
}

.underlying-edit-list-container .react-grid-HeaderRow:first-child .react-grid-HeaderCell.react-grid-HeaderCell--locked:last-child {
  z-index: 2;
}

.underlying-edit-list-container .react-grid-Viewport {
  z-index: 2;
}

.underlying-edit-list-container .react-grid-Header {
  z-index: 2;
}

.underlying-edit-list-container #edit-underlying-button:disabled {
 opacity: 0.5;
}

.underlying-edit-list-container #edit-multiple-underlyings-button:disabled,
.underlying-edit-list-container #delete-multiple-underlyings-button:disabled,
.underlying-edit-list-container #export-multiple-products-button:disabled {
  opacity: 0.5;
  pointer-events: none;
 }
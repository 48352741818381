@use '~@branding/styles.scss' as *;

$text-color: #FFFFFF;
$second-color: #909090;
$gray: #333333;

.dark-mode-theme {
  .b-watchlist__title {
    color: $text-color;
  }

  .b-watchlist__row > div {
    border-color: #444444;
  }

  .b-watchlist__update,
  .b-watchlist__currency {
    color: $second-color;
  }

  .b-watchlist__info .col-separator {
    border-color: $second-color;
  }

  .b-watchlist__diff-col--increase {
    color: $brand-color-dark-buy;
  }

  .b-watchlist__diff-col--decrease {
    color: $brand-color-dark-sell;
  }

  .b-watchlist__percent--red {
    color: $brand-color-dark-sell;
  }

  .b-watchlist__percent--green {
    color: $brand-color-dark-buy;
  }

  .instrument-title {
    color: $brand-color-dark-instrument-title;
  }

  .instrument__price--up {
    color: $brand-color-dark-price-up !important;
  }

  .instrument__price--down {
    color: $brand-color-dark-price-down !important;
  }

  .instrument__error-text {
    color: $brand-color-dark-highlight-error-text !important;
  }

  .instrument__change-rate--up {
    color: $brand-color-dark-change-rate-up;
  }

  .instrument__change-rate--down {
    color: $brand-color-dark-change-rate-down;
  }
}